import { Env } from "./utils";

export const envs: {
  unstable?: Env;
  staging?: Env;
  prod: Env;
  local: Env;
  unused?: Env;
} = {
  staging: {
    production: false,
    app_url: "https://staging.story.com/",
    api_url: "https://api.staging.story.com/",
    imageKit: "https://ik.imagekit.io/storybird/staging/",
  },
  local: {
    production: false,
    app_url: "http://localhost:3000/",
    api_url: "http://localhost:5000/",
    imageKit: "https://ik.imagekit.io/storybird/staging/",
  },
  prod: {
    production: true,
    app_url: "https://story.com/",
    api_url: "https://api.story.com/",
    imageKit: "https://ik.imagekit.io/storybird/",
  },
};
