import { useEffect, useState } from "react";
import { useGetAllUsersQuery } from "../store/base-api";
import PageError from "../components/errors/page-error";
import PaginationBtns from "../components/inputs/pagination-btns";
import LoadingIndicator from "../components/loaders/loading-indicator";
import { useLocation, useNavigate } from "react-router-dom";
import SearchBar from "../components/inputs/search-bar";
import { CURRENT_PAGE_REGEX, updateQueryString } from "../utils";
import UserListItem from "../components/list-items/user-list-item";

export default function Users() {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(search);
  const currentPage = Number(params.get("currentPage") || 1);

  const [searchText, setSearchText] = useState(params.get("search") || "");
  const [pattern, setPattern] = useState(params.get("search") || "");

  const { data, error, isFetching, isLoading } = useGetAllUsersQuery({
    currentPage,
    pattern,
  });

  const [loading, setLoading] = useState(true);

  const handleSearch = (text: string) => {
    setPattern(text);
    const searchQuery = updateQueryString("currentPage", 1, CURRENT_PAGE_REGEX);
    navigate(`${pathname}${searchQuery}`, { replace: true });
  };

  useEffect(() => {
    setLoading(isFetching || isLoading);
  }, [isLoading, isFetching]);

  return (
    <div className="flex flex-col relative flex-1 w-full overflow-y-auto max-h-[calc(100vh-96px)]">
      <div className="sticky z-10 flex flex-wrap px-8 py-2 gap-x-4 gap-y-2 items-center justify-between top-0 bg-white shadow-md">
        <SearchBar
          searchText={searchText}
          setSearchText={setSearchText}
          onSearch={handleSearch}
          placeholder="Search by email..."
        />
        <PaginationBtns totalPages={data?.totalPages || 0} />
      </div>

      {error ? (
        <PageError error={error} />
      ) : (
        <div className="flex flex-col p-4 flex-1">
          <div className="flex flex-col flex-1 gap-2">
            {loading || !data?.items.length ? (
              <div className="flex-1 grid place-items-center">
                {loading ? (
                  <LoadingIndicator />
                ) : (
                  <p>No users found{pattern ? " with that email." : "."}</p>
                )}
              </div>
            ) : (
              data.items.map((user) => <UserListItem key={user.id} {...user} />)
            )}
          </div>
        </div>
      )}
    </div>
  );
}
