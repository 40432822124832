import { BaseError } from "../../models";
import { normalizeError } from "../../utils";

export default function PageError({ error }: { error: BaseError }) {
  const { status, message } = normalizeError(error) || {};

  return (
    <div className="flex-1 grid place-items-center">
      <div className="flex flex-col gap-4 text-center max-w-md p-4">
        <div className="text-2xl font-bold">{status}</div>
        <div>{message || "An unkown error occurred."}</div>
      </div>
    </div>
  );
}
