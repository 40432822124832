import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { Toaster } from "react-hot-toast";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import AppContainer from "./layouts/app-container";
import AmazonRequest from "./pages/amazon-request";
import AmazonRequests from "./pages/amazon-requests";
import Auth from "./pages/auth";
import { store } from "./store/store";
import { Provider } from "react-redux";
import RPIRequests from "./pages/rpi-requests";
import RPIRequest from "./pages/rpi-request";
import Stories from "./pages/stories";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import ViewStory from "./pages/view-story";
import UsersInfo from "./pages/user-info";
import DiscountCodes from "./pages/discount-codes";
import EditDiscountCode from "./pages/edit-discount-code";
import CreateDiscountCode from "./pages/create-discount-code";
import Reports from "./pages/reports";
import ReportedStory from "./pages/reported-story";
import Users from "./pages/users";
import ReportedComment from "./pages/reported-comment";
import StoryItems from "./pages/story-items";
import SingleStoryItem from "./pages/single-story-item";
dayjs.extend(utc);

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppContainer />,
    children: [
      {
        index: true,
        element: <Auth />,
      },
      {
        path: "users",
        element: <Users />,
      },
      {
        path: "users/:id",
        element: <UsersInfo />,
      },
      {
        path: "stories",
        element: <Stories />,
      },
      {
        path: "stories/:id",
        element: <ViewStory />,
      },
      {
        path: "amazon-requests",
        element: <AmazonRequests />,
      },
      {
        path: "amazon-requests/:id",
        element: <AmazonRequest />,
      },
      {
        path: "story-items",
        element: <StoryItems />,
      },
      {
        path: "story-items/:id/:storyItemType",
        element: <SingleStoryItem />,
      },
      {
        path: "rpi-requests",
        element: <RPIRequests />,
      },
      {
        path: "rpi-requests/:id",
        element: <RPIRequest />,
      },
      {
        path: "discount-codes/create-new",
        element: <CreateDiscountCode />,
      },
      {
        path: "discount-codes",
        element: <DiscountCodes />,
      },
      {
        path: "discount-codes/:id",
        element: <EditDiscountCode />,
      },
      {
        path: "reports",
        element: <Reports />,
      },
      {
        path: "reports/:id",
        element: <ReportedStory />,
      },
      {
        path: "/reported-comments/:id",
        element: <ReportedComment />,
      },
    ],
  },
]);

const rootElement = document.getElementById("root");
if (!rootElement) throw new Error("Failed to find the root element");

ReactDOM.createRoot(rootElement).render(
  <StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
      <Toaster position="top-right" />
    </Provider>
  </StrictMode>,
);
