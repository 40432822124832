import { useNavigate } from "react-router-dom";
import {
  CreditSpendType,
  CreditSpendTypeToDescription,
  PdfPurchase,
} from "../../models";
import { formatDate } from "../../utils";

export default function PurchaseListItem({
  webStoryId,
  storyTitle,
  topLevelCategory,
  slug,
  creditSpendType,
  dateOfPurchase,
  isFormSubmitted,
}: PdfPurchase) {
  const navigate = useNavigate();

  const goToPurchaseHandler = () => {
    if (
      creditSpendType === CreditSpendType.AmazonPublishing ||
      creditSpendType === CreditSpendType.AmazonPublishingPDFOnly
    ) {
      navigate(`/amazon-requests/${webStoryId}`);
    } else {
      navigate(`/story-pdfs/${webStoryId}/${creditSpendType}`);
    }
  };

  return (
    <div
      onClick={goToPurchaseHandler}
      className="flex justify-between items-center gap-2 p-4 border-2 border-neutral-50 rounded-2xl bg-white shadow-md cursor-pointer hover:bg-neutral-50"
    >
      <div>
        <p className="text-2xl font-semibold">{storyTitle}</p>
        <p>{CreditSpendTypeToDescription[creditSpendType]}</p>
        <p className="text-sm text-gray-500">
          Purchased on {formatDate(dateOfPurchase)}
        </p>
      </div>
      {(isFormSubmitted !== undefined || isFormSubmitted !== null) && (
        <div
          className={`px-2 py-1 rounded-full text-xs ${isFormSubmitted ? "bg-green-100 text-green-800" : "bg-yellow-100 text-yellow-800"}`}
        >
          {isFormSubmitted ? "Submitted" : "Not Submitted"}
        </div>
      )}
    </div>
  );
}
