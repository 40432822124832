import { forwardRef, HTMLProps } from "react";
import { ReactComponent as LogoSvg } from "../../assets/logo/logo.svg";

const Logo = forwardRef<
  HTMLDivElement,
  HTMLProps<HTMLDivElement> & { logoClassName?: string }
>((props, ref) => {
  return (
    <div
      {...props}
      className={`h-16 w-16 p-2 ${
        props?.className || ""
      } overflow-hidden rounded-full bg-logo-bg flex flex-row justify-center items-center`}
    >
      <LogoSvg className={`flex flex-1 ${props?.logoClassName}`} />
    </div>
  );
});
Logo.displayName = "Logo";
export default Logo;
