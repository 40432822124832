import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "./store/store";
import { clearAuthState } from "./store/auth-store";
import { useEffect, useState } from "react";

export const useLogout = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const logout = () => {
    dispatch(clearAuthState());
    navigate("/", { replace: true });
  };

  return logout;
};
