import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";

export const PAGINATED_PAGE_SIZE = 30;

export type BaseError = FetchBaseQueryError | SerializedError;

export interface PaginatedList<T> {
  items: T[];
  totalItems: number;
  pageSize: number;
  totalPages: number;
  currentPage: number;
}

export const defaultPaginatedList = {
  items: [],
  totalItems: 0,
  pageSize: PAGINATED_PAGE_SIZE,
  totalPages: 0,
  currentPage: 1,
};

export enum AmazonPublishLifecycle {
  Requested,
  Processing,
  Published,
  Rejected,
  SelfPublished,
}

export enum MyItems {
  WatermarkedPDF,
  PremiumPDF,
}

enum ReportsTabs {
  Stories,
  Comments,
}

export const REPORTS_TABS = Object.entries(ReportsTabs)
  .filter(([key]) => isNaN(Number(key)))
  .map(([key, value]) => ({
    key: key.toLowerCase(),
    value,
  }));

export const AMAZON_TABS = Object.entries(AmazonPublishLifecycle)
  .filter(([key]) => isNaN(Number(key)))
  .map(([key, value]) => ({
    key: key.toLowerCase(),
    value,
  }));

export const MY_ITEMS_TABS = Object.entries(MyItems)
  .filter(([key]) => isNaN(Number(key)))
  .map(([key, value]) => ({
    key: key.toLowerCase(),
    value,
  }));

export interface AmazonRequestInterface {
  webStoryId: string;
  topLevelCategory: string;
  slug: string;
  coverPDFUrl: string;
  gutsPDFUrl: string;
  authorFirstName: string;
  authorLastName: string;
  amazonPublishLifecycle: AmazonPublishLifecycle;
  amazonUrl: string | null;
  publicationDate: string;
  amazonPublishDate: string | null;
  storyTitle: string;
  storySummary: string;
  returnBookMetaDataDTO: {
    title: string;
    subtitle: string;
    author: string;
    summary: string;
    categories: string | null;
    seoKeywords: string;
    ageGroupMin: string;
    ageGroupMax: string;
    amazonMarketplace: number;
  };
  created: string;
}

export type UserRole = "Admin" | "Contractor" | null;

export interface User {
  token: string;
  role: UserRole;
}

export enum BookOrderLifecycle {
  Requested,
  Generating,
  ReadyToSubmit,
  RPIReceived,
  RPIPrinting,
  RPIShipped,
  Delivered,
  Failed,
}

export enum RpiTab {
  Requested,
  Shipped,
  Failed,
}

export const RPI_TABS = Object.entries(RpiTab)
  .filter(([key]) => isNaN(Number(key)))
  .map(([key, value]) => ({
    key: key.toLowerCase(),
    value,
  }));

export enum ShippingClassification {
  priority,
  express,
  standard,
  economy,
}

export enum CreditSpendType {
  StoryGeneration, // Entire Story Generation
  StorySegmentEdit, // Edit a text segment
  AudioGeneration, // Generate audio for entire story
  AudioRegeneration, // Regenerate audio for a single segment
  ImageRegeneration, // Regenerate image for a single segment
  AmazonPublishing, // We publish to Amazon
  AmazonPublishingPDFOnly, // Give Amazon PDF Only
  WatermarkedStoryPDF, // Downloadable Watermarked PDF
  OriginalStoryPDF, // Downloadable Original PDF
  StoryBookPDF = 17, // StoryBook PDF
}

export const CreditSpendTypeToDescription = {
  [CreditSpendType.StoryGeneration]: "Story Generation",
  [CreditSpendType.StorySegmentEdit]: "Edit a text segment",
  [CreditSpendType.AudioGeneration]: "Generate audio for entire story",
  [CreditSpendType.AudioRegeneration]: "Regenerate audio for a single segment",
  [CreditSpendType.ImageRegeneration]: "Regenerate image for a single segment",
  [CreditSpendType.AmazonPublishing]: "Fully Managed Amazon Publish",
  [CreditSpendType.AmazonPublishingPDFOnly]: "Amazon Self-Publish Kit",
  [CreditSpendType.WatermarkedStoryPDF]: "Watermarked PDF",
  [CreditSpendType.OriginalStoryPDF]: "Premium PDF",
};

export interface BookOrder {
  coverPDFUrl: string;
  gutsPDFUrl: string;
  authorFirstName: string;
  authorLastName: string;
  quantity: number;
  bookOrderLifecycle: BookOrderLifecycle;
  orderReference: string;
  orderPrice: number;
  shippingPrice: number;
  totalPrice: number;
  storyTitle: string;
  coverImage: string;
  name: string;
  address1: string;
  address2: string;
  city: string;
  postal: string;
  country: string;
  phone: string;
  email: string;
  state: string;
  shippingClassification: ShippingClassification;
  id: string;
  webStoryId: string;
  created: string;
}

export interface TinyStoryUser {
  id: string;
  name: string;
  email: string;
  created: string;
}

export interface PostPaidUsage {
  allowanceType: string;
  creditSpendType: string;
  extraAllowance: number;
  isPaid: boolean;
  paidDate: string | null;
}

export interface StoryUser extends TinyStoryUser {
  walletBalance: number;
  webStories: WebStory[];
  pdfPurchases?: PdfPurchase[];
  postPaidUsages?: PostPaidUsage[];
}

export interface BaseWebStory {
  id: string;
  created: string;
  isDone: boolean;
  isPublic: boolean;
  isPluginGenerated: boolean;
  topLevelCategory: string;
  slug: string;
  storyType: number;
  shortId: string;
  storyTitle: string;
  storyPrompt: string;
  summary: string;
  storyLikes: number;
  coverImage: string;
  language: string;
}

export interface WebStory extends BaseWebStory {
  isTemp: boolean;
  user: TinyStoryUser;
  cancelled: boolean;
  storySegments: StorySegment[];
  storyPodcast: Podcast;
  amazonBook: AmazonRequestInterface;
  bookOrders: BookOrder[];
}

export interface StoryItem {
  webStoryId: string;
  slug: string;
  storyTitle: string;
  topLevelCategory: string;
  created: string;
  creditSpendType: CreditSpendType;
  coverImage: string;
  pdfUrl: string;
}

export interface PdfPurchase {
  webStoryId: string;
  storyTitle: string;
  topLevelCategory: string;
  slug: string;
  creditSpendType: CreditSpendType;
  dateOfPurchase: string;
  isFormSubmitted: boolean;
}

export interface StoryRequest {
  childName: string;
  gender: Gender;
  childAge: number;
  topicOne: string;
  topicTwo: string;
  topicThree: string;
  locationName: string;
}

export enum Gender {
  Male,
  Female,
  PreferNotToSay,
}

export interface StorySegment {
  textContent: string;
  imageKey: string;
  index: number;
  imageRegenerating: boolean;
}

export interface Podcast {
  created: string;
  appleLink: string;
  spotifyLink: string;
  podcastLifecycle: PodcastLifecycle;
  voiceType: VoiceType;
}

export enum BookLifecycle {
  Requested,
  Validating,
  Publishing,
  Shipping,
  Cancelled,
  Error,
}

export enum PodcastLifecycle {
  Requested,
  Processing,
  Publishing,
  Error,
}

export enum VoiceType {
  GenericMale,
  GenericFemale,
}

export interface DiscountCode {
  code: string;
  discountPercentage: number;
  isActive: boolean;
  expirationDate: string;
  discountCodeType: DiscountCodeType;
}

export enum DiscountCodeType {
  None,
  BookOrder,
}

export interface DiscountCodeReturn extends DiscountCode {
  id: string;
  created: string;
}

export enum StoryReportReason {
  InappropriateText,
  NotLoading,
  HateSpeech,
  Other,
  InapropriateImages,
}

export enum CommentReportReason {
  InappropriateText,
  HateSpeech,
  Irrelevant,
  Other,
}

export interface StoryReport {
  storyReportReason: StoryReportReason;
  otherReason: string;
}

export interface ReportedWebStory extends BaseWebStory {
  user: TinyStoryUser;
  storyReports: (StoryReport & { user: TinyStoryUser })[];
}

export interface ReportedComment {
  comment: CommentData;
  commentReportReason: CommentReportReason;
  otherReason: string;
  user: TinyStoryUser;
}

export interface CommentData {
  id: string;
  userId: string;
  webStoryId: string;
  created: string;
  commentText: string;
  profileName: string;
}

export enum StoryType {
  Book = 0,
  Video = 1,
  Trends = 2,
}

export interface PurchaseCategory {
  id: CreditSpendType;
  title: string;
  value: string;
}

export interface StoryCategory {
  id: StoryType[];
  title: string;
  value: string;
}
