import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { User, UserRole } from "../models";
import { decodeToken } from "../utils";

interface AuthState {
  token: string;
  role: UserRole;
  hasAuth: boolean;
}

const initialState: AuthState = {
  token: "",
  role: null,
  hasAuth: false,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    enableAuth: (state, { payload }: PayloadAction<string>) => {
      localStorage.setItem("authToken", payload);
      const { token, role } = decodeToken(payload);
      state.token = token;
      state.role = role;
      state.hasAuth = true;
    },
    clearAuthState: (state) => {
      localStorage.removeItem("authToken");
      state = initialState;
    },
  },
});

export const { enableAuth, clearAuthState } = authSlice.actions;
export default authSlice.reducer;
