import { ReactComponent as AlertTriangleIcon } from "../../assets/icons/alert-triangle.svg";
import { BaseError } from "../../models";
import { normalizeError } from "../../utils";

export default function FormError({ error }: { error?: BaseError }) {
  if (!error) return null;

  const { message } = normalizeError(error);

  return (
    <div className="flex items-center gap-2 border-2 bg-error-100 rounded-2xl border-error-500 text-error-600 font-semibold py-2 px-4 max-w-max">
      <AlertTriangleIcon />
      {message || "An unknown error occurred."}
    </div>
  );
}
