import { ReactComponent as DownloadIcon } from "../../assets/icons/download.svg";
import { ReactComponent as AlertTriangleIcon } from "../../assets/icons/alert-triangle.svg";

export default function Downloadable({ url }: { url: string }) {
  if (!url) {
    return (
      <div className="flex items-center gap-2 uppercase text-sm font-semibold text-error-500">
        <AlertTriangleIcon />
        <span>File not found</span>
      </div>
    );
  }

  return (
    <a
      href={url}
      download
      rel="noreferrer"
      target="_blank"
      className="flex items-center gap-2 text-sm uppercase font-semibold text-primary-500 hover:text-primary-600"
    >
      <DownloadIcon />
      <span>Download</span>
    </a>
  );
}
