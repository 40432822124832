import { PostPaidUsage } from "../../models";
import { formatDate } from "../../utils";

export default function PostPaidUsageListItem({
  allowanceType,
  creditSpendType,
  extraAllowance,
  isPaid,
  paidDate,
  index,
}: PostPaidUsage & { index: number }) {
  return (
    <div className="flex items-baseline gap-6 p-4 border-2 border-neutral-50 rounded-2xl bg-white shadow-md hover:bg-neutral-50">
      <p className="text-sm text-gray-600 font-semibold">{index + 1}.</p>
      <div className="flex flex-col gap-1">
        <div className="flex items-center gap-2">
          <p className="text-lg font-semibold">{allowanceType}</p>
          <span
            className={`px-3 py-1 rounded-full text-sm font-medium ${
              isPaid
                ? "bg-green-100 text-green-800"
                : "bg-yellow-100 text-yellow-800"
            }`}
          >
            {isPaid ? "Paid" : "Pending"}
          </span>
        </div>
        <div className="grid grid-cols-[auto,1fr] gap-x-2 text-gray-600">
          <span className="font-semibold">Extra Allowance:</span>
          <span>{extraAllowance}</span>
          <span className="font-semibold">Type:</span>
          <span>{creditSpendType || "N/A"}</span>

          <span className="font-semibold">Paid Date:</span>
          <span>{(paidDate && formatDate(paidDate)) || "N/A"}</span>
        </div>
      </div>
    </div>
  );
}
