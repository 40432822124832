import { Link, useLocation } from "react-router-dom";
import Logo from "../components/ui/logo";
import { useAppSelector } from "../store/store";
import { useLogout } from "../app-hooks";

export default function NavBar() {
  const logout = useLogout();
  const { pathname } = useLocation();
  const { role } = useAppSelector(({ auth }) => auth);

  return (
    <header className="flex p-4 items-center justify-between text-white bg-secondary-900">
      <div className="flex items-center text-3xl gap-4 min-w-max font-bold">
        <Logo className="h-16 w-16 rounded-full" logoClassName="w-8 h-8" />
        Story.com Admin
      </div>

      <div className="flex gap-8 p-2 items-center mr-8">
        {role === "Admin" ? (
          <>
            {/*<Link*/}
            {/*  aria-selected={pathname.includes("/discount-codes")}*/}
            {/*  to="/discount-codes"*/}
            {/*  className="topbar-nav-link"*/}
            {/*>*/}
            {/*  Discount Codes*/}
            {/*</Link>*/}
            {/*<Link*/}
            {/*  aria-selected={pathname.includes("/reports")}*/}
            {/*  to="/reports"*/}
            {/*  className="topbar-nav-link"*/}
            {/*>*/}
            {/*  Reports*/}
            {/*</Link>*/}
            <Link
              aria-selected={pathname.includes("/users")}
              to="/users"
              className="topbar-nav-link"
            >
              Users
            </Link>
            <Link
              aria-selected={pathname.includes("/stories")}
              to="/stories"
              className="topbar-nav-link"
            >
              Stories
            </Link>
            {/*<Link*/}
            {/*  aria-selected={pathname.includes("/rpi-requests")}*/}
            {/*  to="/rpi-requests"*/}
            {/*  className="topbar-nav-link"*/}
            {/*>*/}
            {/*  RPI Requests*/}
            {/*</Link>*/}
            {/*<Link*/}
            {/*  aria-selected={pathname.includes("/story-items")}*/}
            {/*  to="/story-items"*/}
            {/*  className="topbar-nav-link"*/}
            {/*>*/}
            {/*  Story Items*/}
            {/*</Link>*/}
          </>
        ) : null}
        <Link
          aria-selected={pathname.includes("/amazon-requests")}
          to="/amazon-requests"
          className="topbar-nav-link"
        >
          Amazon Requests
        </Link>

        <button className="btn-primary" type="button" onClick={logout}>
          Sign Out
        </button>
      </div>
    </header>
  );
}
