import { useLocation, useNavigate } from "react-router-dom";
import { CURRENT_PAGE_REGEX, updateQueryString } from "../../utils";

export default function PaginationBtns({ totalPages }: { totalPages: number }) {
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const params = new URLSearchParams(search);
  const currentPage = parseInt(params.get("currentPage") || "1");

  const goToPage = (pageNum: number) => {
    if (pageNum < 1 || pageNum > totalPages) return;
    const searchQuery = updateQueryString(
      "currentPage",
      pageNum,
      CURRENT_PAGE_REGEX,
    );
    navigate(`${pathname}${searchQuery}`, { replace: true });
  };

  return (
    <div className="flex gap-4 items-center">
      <div className="text-sm">
        Showing page {currentPage} of {totalPages}
      </div>
      <button
        className="font-semibold px-4 py-2 hover:text-primary-500 disabled:!text-neutral-400"
        disabled={currentPage <= 1}
        onClick={() => goToPage(1)}
      >
        First
      </button>
      <button
        className="font-semibold px-4 py-2 hover:text-primary-500 disabled:!text-neutral-400"
        disabled={currentPage - 1 <= 0}
        onClick={() => goToPage(currentPage - 1)}
      >
        Prev
      </button>
      <button
        className="font-semibold px-4 py-2 hover:text-primary-500 disabled:!text-neutral-400"
        disabled={currentPage + 1 > totalPages}
        onClick={() => goToPage(currentPage + 1)}
      >
        Next
      </button>
      <button
        className="font-semibold px-4 py-2 hover:text-primary-500 disabled:!text-neutral-400"
        disabled={currentPage >= totalPages}
        onClick={() => goToPage(totalPages)}
      >
        Last
      </button>
    </div>
  );
}
