import dayjs from "dayjs";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { enableAuth } from "../store/auth-store";
import { useAppDispatch, useAppSelector } from "../store/store";
import { decodeToken } from "../utils";
import TopBar from "./topbar";
import { useLogout } from "../app-hooks";

export default function AppContainer() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const logout = useLogout();
  const { hasAuth } = useAppSelector(({ auth }) => auth);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    const { exp, role } = decodeToken(token || "") || {};
    if (!token || dayjs().isAfter(dayjs(exp * 1000))) return logout();
    if (!hasAuth) dispatch(enableAuth(token));
    if (!/(amazon-requests).*/.test(pathname) && role !== "Admin")
      navigate("/amazon-requests", { replace: true });
  }, [pathname]);

  return (
    <>
      {pathname === "/" ? null : <TopBar />}
      <main className="flex flex-col flex-1 w-full">
        <Outlet />
      </main>
    </>
  );
}
