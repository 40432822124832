import { useRef } from "react";
import { Link, useLocation } from "react-router-dom";

const CURRENT_TAB_REGEX = /currentTab=([^&]+)/;

export default function TabbedMenu<T extends { [key: string]: any }>({
  tabs,
}: {
  tabs: T[];
}) {
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const current = params.get("currentTab") || tabs[0].key;
  const tab = tabs.find(({ key }) => key === current) || tabs[0];

  const mainRef = useRef<HTMLDivElement>(null);
  const refs = [...Array(tabs.length)].map(() => useRef<HTMLSpanElement>(null));

  const getIndicatorStyle = () => {
    const { x } = mainRef.current?.getBoundingClientRect() || {};
    const ref = refs[tabs.findIndex(({ key }) => tab.key === key)];
    if (!ref?.current || x == null)
      return { transform: `translateX(0)`, width: 0, display: "none" };
    const { left, width } = ref.current.getBoundingClientRect();
    const transform = `translateX(${left - x}px)`;
    return { transform, width, display: "block" };
  };

  const getLinkUrl = (tab: T) => {
    if (!search) return `?currentTab=${tab}`;
    const match = search.match(CURRENT_TAB_REGEX);
    if (match) return search.replace(match[0], `currentTab=${tab}`);
    return `${search}&currentTab=${tab}`;
  };

  return (
    <div ref={mainRef} className="relative -my-2 flex items-center gap-4">
      {tabs.map(({ key, value }, i) => (
        <Link
          key={`${key}-tab-option`}
          to={getLinkUrl(key)}
          replace={true}
          className="tab"
          aria-selected={value === tab?.value}
        >
          <span ref={refs[i]}>{key}</span>
        </Link>
      ))}
      <div className="tab-indicator" style={getIndicatorStyle()} />
    </div>
  );
}
