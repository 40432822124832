import { useParams, useNavigate } from "react-router-dom";
import {
  useAddCreditsMutation,
  useDeleteUserAccountMutation,
  useGetUserInfoQuery,
} from "../store/base-api";
import PageError from "../components/errors/page-error";
import LoadingIndicator from "../components/loaders/loading-indicator";
import StoryListItem from "../components/list-items/story-list-item";
import { Fragment, useState } from "react";
import {
  blockInvalidChar,
  formatDate,
  getGenderString,
  PURCHASE_CATEGORIES,
  splitOnUppercase,
  STORY_CATEGORIES,
} from "../utils";
import { BaseError, CreditSpendType, StoryType } from "../models";
import FormError from "../components/errors/form-error";
import PurchaseListItem from "../components/list-items/purchase-list-item";
import toast from "react-hot-toast";
import SearchBar from "../components/inputs/search-bar";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../components/ui/accordion";
import PostPaidUsageListItem from "../components/list-items/post-paid-usage-list-item";

export default function UserInfo() {
  const { id } = useParams();
  const [searchText, setSearchText] = useState("");
  const {
    data,
    isLoading,
    error: fetchError,
  } = useGetUserInfoQuery(id!, { skip: !id });
  const [deleteUser] = useDeleteUserAccountMutation();
  const [addCredits] = useAddCreditsMutation();

  const [creditsToAdd, setCreditsToAdd] = useState(0);
  const [creditType, setCreditType] = useState("");

  const [deleting, setDeleting] = useState(false);
  const [addingCredits, setAddingCredits] = useState(false);
  const [error, setError] = useState<BaseError>();

  const getObjectValue = (key: string, value: any) => {
    switch (key) {
      case "created":
        return formatDate(value);
      case "gender":
        return getGenderString(value);
      case "subscriptionCreatedDate":
        return formatDate(value);
      case "subscriptionStartDate":
        return formatDate(value);
      case "subscriptionEndDate":
        return formatDate(value);
      case "subscriptionNextRefreshDate":
        return formatDate(value);
      default:
        return value;
    }
  };

  const deleteUserHandler = async () => {
    if (
      deleting ||
      !id ||
      !window.confirm("Are you sure you want to delete this user?")
    )
      return;
    setDeleting(true);

    try {
      await deleteUser(id).unwrap();
    } catch (e: any) {
      setError(e);
    } finally {
      setDeleting(false);
    }
  };

  const addCreditsHandler = async () => {
    if (
      addingCredits ||
      !id ||
      creditsToAdd < 1 ||
      !data?.email ||
      !window.confirm(
        `Are you sure you want to add $${creditsToAdd} = [${creditsToAdd * 20}] tokens?`,
      )
    )
      return;

    setAddingCredits(true);
    try {
      const addCreditsPromise = addCredits({
        userId: id,
        credits: creditsToAdd,
        creditType: creditType,
      }).unwrap();

      toast.promise(addCreditsPromise, {
        loading: "Adding credits...",
        success: `Successfully added ${creditsToAdd} credits`,
        error: (err) => `Error: ${"Failed to add credits"}`,
      });

      await addCreditsPromise;
    } catch (e: any) {
      setError(e);
    } finally {
      setAddingCredits(false);
      // window.location.reload();
    }
  };

  const handleSearch = (text: string) => {
    // Navigate to the users route with search params
    window.location.href = `/users?search=${encodeURIComponent(text)}&currentPage=1`;
  };

  if (fetchError) return <PageError error={fetchError} />;

  return (
    <div className="flex flex-1 w-full p-4 overflow-y-auto max-h-[calc(100vh-96px)]">
      <div className="flex p-8 flex-col flex-1 bg-white border-2 border-neutral-50 rounded-2xl min-h-[calc(100vh-128px)] h-max shadow-xl">
        <div className="sticky z-10 flex mb-4">
          <SearchBar
            searchText={searchText}
            setSearchText={setSearchText}
            onSearch={handleSearch}
            placeholder="Search users by email..."
          />
        </div>
        {isLoading || !data ? (
          <div className="flex-1 grid place-items-center">
            <LoadingIndicator />
          </div>
        ) : (
          <div className="flex flex-col gap-8">
            <div className="flex flex-col gap-4">
              <h3 className="text-2xl font-semibold">User Info</h3>

              <div className="flex gap-4 justify-between flex-wrap">
                <div className="flex-1 grid auto-rows-min gap-x-8 gap-y-2 grid-cols-[max-content,_1fr]">
                  {Object.entries(data)
                    .filter(
                      ([key]) =>
                        key !== "webStories" &&
                        key !== "pdfPurchases" &&
                        key !== "walletBalance" &&
                        key !== "postPaidUsages",
                    )
                    .map(([key, value]) => (
                      <Fragment key={`user-info-${key}`}>
                        <p className="font-semibold text-lg capitalize">
                          {splitOnUppercase(key)}
                        </p>
                        <div>{getObjectValue(key, value) || "N/A"}</div>
                      </Fragment>
                    ))}
                </div>

                <div className="flex flex-col gap-4 w-1/4 border border-slate-500 rounded-lg p-4 h-fit">
                  <p className="font-semibold text-lg capitalize">
                    Add Credits
                  </p>

                  <Fragment key={`user-info-add-credits`}>
                    <div className="flex flex-col gap-2">
                      <label htmlFor="creditType" className="font-medium">
                        Credit Type
                      </label>
                      <select
                        id="creditType"
                        value={creditType}
                        onChange={(e) => setCreditType(e.target.value)}
                        className="border-2 border-neutral-200 rounded-md p-2 w-full mb-2"
                      >
                        <option value="">Select Credit Type</option>
                        <option value="StoryBooks">StoryBooks</option>
                        <option value="Videos">Videos</option>
                        <option value="Credits">Credits</option>
                      </select>
                      <label htmlFor="creditsAmount" className="font-medium">
                        Amount
                      </label>
                      <input
                        id="creditsAmount"
                        type="text"
                        pattern="[0-9]*"
                        min="0"
                        placeholder="Add Credits..."
                        value={creditsToAdd === 0 ? "" : creditsToAdd}
                        className={`border-2 border-neutral-200 rounded-md p-2 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`}
                        onChange={(e) => {
                          const value = e.target.value.replace(/\D/g, "");
                          if (value === "" || /^\d+$/.test(value)) {
                            setCreditsToAdd(value === "" ? 0 : Number(value));
                          }
                        }}
                        onKeyDown={blockInvalidChar}
                        onPaste={(e) => {
                          const pastedData = e.clipboardData.getData("text");
                          if (!/^\d+$/.test(pastedData)) {
                            e.preventDefault();
                          }
                        }}
                      />
                    </div>
                    <button
                      className="btn-primary"
                      disabled={addingCredits}
                      onClick={addCreditsHandler}
                    >
                      Add Funds
                    </button>
                  </Fragment>
                </div>
              </div>

              {data.email !== "anonymous@storybird.ai" ? (
                <>
                  <FormError error={error} />

                  <button
                    className="btn-error"
                    disabled={deleting}
                    onClick={deleteUserHandler}
                  >
                    Delet{deleting ? "ing" : "e"} User
                  </button>
                </>
              ) : null}
            </div>
            <div>
              <h3 className="text-2xl mb-4 font-semibold">Purchases</h3>
              {data.pdfPurchases && data.pdfPurchases.length > 0 ? (
                <Accordion type="multiple" className="w-full">
                  <div className="grid grid-cols-2 gap-4">
                    {PURCHASE_CATEGORIES.map((category) => {
                      const categoryPurchases =
                        data.pdfPurchases?.filter(
                          (purchase) =>
                            purchase.creditSpendType === category.id,
                        ) || [];

                      return (
                        <AccordionItem
                          key={category.value}
                          value={category.value}
                          className="w-full"
                        >
                          <AccordionTrigger className="text-xl font-medium shadow-xl border border-input bg-slate-100 p-4 rounded-lg">
                            {category.title} ({categoryPurchases.length})
                          </AccordionTrigger>
                          <AccordionContent className="max-h-[250px] bg-slate-200 border border-solid rounded-lg overflow-scroll px-2">
                            <div className="flex flex-col gap-2 pt-2">
                              {categoryPurchases.length > 0 ? (
                                categoryPurchases.map((purchase, i) => (
                                  <PurchaseListItem
                                    key={`${category.value}-purchase-${i + 1}`}
                                    {...purchase}
                                  />
                                ))
                              ) : (
                                <p className="text-gray-500 p-2">
                                  No {category.title.toLowerCase()} found.
                                </p>
                              )}
                            </div>
                          </AccordionContent>
                        </AccordionItem>
                      );
                    })}
                  </div>
                </Accordion>
              ) : (
                <p className="text-black">This user has no PDF purchases.</p>
              )}
            </div>
            <div>
              <h3 className="text-2xl mb-4 font-semibold">User Stories</h3>
              {data.webStories && data.webStories.length > 0 ? (
                <Accordion type="multiple" className="w-full">
                  <div className="grid grid-cols-2 gap-4">
                    {STORY_CATEGORIES.map((category) => {
                      const categoryStories =
                        data.webStories?.filter((story) =>
                          category.id.includes(story.storyType),
                        ) || [];

                      return (
                        <AccordionItem
                          key={category.value}
                          value={category.value}
                          className="w-full"
                        >
                          <AccordionTrigger className="text-xl font-medium shadow-xl border border-input bg-slate-100 p-4 rounded-lg">
                            {category.title} ({categoryStories.length})
                          </AccordionTrigger>
                          <AccordionContent className="max-h-[350px] bg-slate-200 border border-solid rounded-lg overflow-scroll px-2">
                            <div className="flex flex-col gap-2 pt-2">
                              {categoryStories.length > 0 ? (
                                categoryStories.map((story, i) => (
                                  <StoryListItem
                                    key={`${category.value}-story-${i + 1}`}
                                    {...{ ...story, user: data }}
                                  />
                                ))
                              ) : (
                                <p className="text-gray-500 p-2">
                                  No {category.title.toLowerCase()} found.
                                </p>
                              )}
                            </div>
                          </AccordionContent>
                        </AccordionItem>
                      );
                    })}
                  </div>
                </Accordion>
              ) : (
                <p className="text-black">
                  This user has no stories or videos.
                </p>
              )}
            </div>
            <div>
              <h3 className="text-2xl mb-4 font-semibold">Post-Paid Usage</h3>
              {data.postPaidUsages && data.postPaidUsages.length > 0 ? (
                <Accordion type="single" collapsible className="w-1/2">
                  <AccordionItem value="post-paid-usage" className="w-full">
                    <AccordionTrigger className="text-xl font-medium shadow-xl border border-input bg-slate-100 p-4 rounded-lg">
                      All Usage Records ({data.postPaidUsages.length})
                    </AccordionTrigger>
                    <AccordionContent className="max-h-[350px] bg-slate-200 border border-solid rounded-lg overflow-scroll px-2">
                      <div className="flex flex-col gap-2 pt-2">
                        {data.postPaidUsages.map((usage, i) => (
                          <PostPaidUsageListItem
                            key={`post-paid-usage-${i + 1}`}
                            index={i}
                            {...usage}
                          />
                        ))}
                      </div>
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              ) : (
                <p className="text-black">
                  This user has no post-paid usage records.
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
